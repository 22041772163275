export default [{
    'code': 'self',
    'name': '个人',
    'roleType': 'role'
  },
  {
    'code': 'rs',
    'name': '人事',
    'roleType': 'role'
  },
  {
    'code': 'yz',
    'name': '院长',
    'roleType': 'position'
  },
  {
    'code': 'fyz',
    'name': '副院长',
    'roleType': 'position'
  },
  {
    'code': 'sj',
    'name': '书记',
    'roleType': 'position'
  },
  {
    'code': 'fsj',
    'name': '副书记',
    'roleType': 'position'
  },
  {
    'code': 'kz',
    'name': '科长',
    'roleType': 'position'
  },
  {
    'code': 'fkz',
    'name': '副科长',
    'roleType': 'position'
  },
  {
    'code': 'zr',
    'name': '主任',
    'roleType': 'position'
  },
  {
    'code': 'fzr',
    'name': '副主任',
    'roleType': 'position'
  },
  {
    'code': 'hsz',
    'name': '护士长',
    'roleType': 'position'
  },
  {
    'code': 'fhsz',
    'name': '副护士长',
    'roleType': 'position'
  },
  {
    'code': 'ys',
    'name': '医生',
    'roleType': 'position'
  },
  {
    'code': 'hs',
    'name': '护士',
    'roleType': 'position'
  },
  {
    'code': 'other',
    'name': '成员',
    'roleType': 'position'
  }]
  