import {
  menuRequest,
  funRequest,
  roleRequest
} from '@/api'

import {
  tools
} from '@/fmlib'

import ucenterMenus from './menus'

import ucenterRoles from './roles'

import {
  getOtherSysMenusConfig,
  getOtherSysRolesConfig
} from '@/sys'

const menus = ucenterMenus.concat(getOtherSysMenusConfig())

console.log(menus)

const dealRoles = (roles) => {
  let newRoles = []
  let codes = []
  roles.forEach(v => {
    if (!codes.includes(v.code)) {
      codes.push(v.code)
      newRoles.push(v)
    }
  })
  return newRoles
}

const roles = dealRoles(ucenterRoles.concat(getOtherSysRolesConfig()))

import {
  updateFun
} from '@/syslib/update_fun'

const getConfigMenu = async (hisMenus, cMenus, pMenu, menuData) => {
  pMenu = pMenu || null
  let i = 0
  while (i < cMenus.length) {
    let menu = cMenus[i]
    let menuItem = {
      clientType: menu.clientType || 'web',
      icon: menu.icon || undefined,
      id: undefined,
      pId: pMenu ? pMenu.id : 0,
      url: menu.url || undefined,
      sys: menu.sys,
      code: menu.code || menu.url,
      name: menu.name,
      type: menu.type || (menu.url ? 'sys_page' : 'menu'),
      sort: i + 1
    }
    menuData.push(menuItem)
    if (!menuItem.code) {
      console.error('数据异常,code为空', menuItem)
      break;
    }
    let findMenu = hisMenus.find(v => v.code === menuItem.code)
    if (findMenu) {
      menuItem.id = findMenu.id
      menuItem.funs = findMenu.funs
      await menuRequest.update(menuItem.id, menuItem)
    } else {
      menuItem.id = await menuRequest.add(menuItem)
    }
    if (menuItem.id && menuItem.url) {
      await updateFun([menuItem])
    }
    if (menu.children && menu.children.length > 0) {
      await getConfigMenu(hisMenus, menu.children, menuItem, menuData)
    }
    i += 1
  }
}

export const initMenu = async () => {
  // 新增或更新所有菜单 并更新其功能
  let hisMenus = await menuRequest.get()
  hisMenus = tools.treeToList(hisMenus, 'children')

  let configMenus = []
  await getConfigMenu(hisMenus, menus, null, configMenus)
}

export const initRole = async () => {
  // 新增或更新所有菜单 并更新其功能
  let hisMenus = await menuRequest.get()
  let hisRoles = await roleRequest.get()
  let funs = await funRequest.get()
  hisMenus = tools.treeToList(hisMenus, 'children')
  let configMenus = []
  tools.treeToList(JSON.parse(JSON.stringify(menus)), 'children', configMenus)
  let hisNull = false
  configMenus.forEach((v) => {
    v.code = v.code || v.url
    let findData = hisMenus.find(v1 => v1.code === v.code)
    v.id = findData ? findData.id : null
    v.roleCodes = v.roles ? v.roles.map(v1 => typeof(v1) === 'string' ? v1 : Object.keys(v1)[0]) : []
    v.roleFuns = {}
    if (v.id && v.url && v.roles) {
      v.roles.forEach((v1) => {
        if (typeof(v1) === 'string') {
          v.roleFuns[v1] = funs.filter(v4 => v4.funKey.includes(v.url + '-')).map(v5 => v5.id)
        } else {
          let roleKey = Object.keys(v1)[0]
          v.roleFuns[roleKey] = v1[roleKey].map(v4 => {
            let data2 = funs.find(v5 => v5.funKey === v.url + '-' + v4)
            if (data2) {
              return data2.id
            } else {
              console.log('配置货数据异常，功能未找到:' + v.url + '-' + v4)
              hisNull = true
            }
          })
        }
      })
    }
  })
  if (hisNull) {
    throw new Error('配置货数据异常，部分功能id为空')
  }
  let i = 0
  while (i < roles.length) {
    let role = roles[i]
    let funIds = []
    configMenus.filter(v => v.roleFuns && Object.keys(v.roleFuns).includes(role.code)).forEach(v => {
      funIds = funIds.concat(v.roleFuns[role.code])
    })
    let menuIds = configMenus.filter(v => v.roleCodes && v.roleCodes.length > 0 && v.roleCodes.includes(role.code)).map(v2 => v2.id)
    role.menuIds = menuIds
    role.funIds = funIds
    let hisRole = hisRoles.find(v => v.code === role.code)
    i += 1
    if (hisRole) {
      await roleRequest.update(hisRole.id, role)
    } else {
      await roleRequest.add(role)
    }
  }
}
