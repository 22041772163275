export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/ucenter/sys_config',
      method: 'GET'
    },
    {
      path: '/ucenter/user',
      method: 'GET'
    }]
  },
  new: {
    funKey: 'new',
    name: '新增',
    apis: [{
      path: '/ucenter/sys_config',
      method: 'POST'
    }]
  },
  update: {
    funKey: 'update',
    name: '修改',
    apis: [{
      path: '/ucenter/sys_config',
      method: 'POST'
    }]
  },
  del: {
    funKey: 'del',
    name: '删除',
    apis: [{
      path: '/ucenter/sys_config/{id}',
      method: 'DELETE'
    }]
  }
}