export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/ucenter/system/funs',
      method: 'GET'
    }]
  },
  update_all: {
    funKey: 'update_all',
    name: '全部更新',
    apis: [{
      path: '/ucenter/system/funs',
      method: 'POST'
    },
    {
      path: '/ucenter/system/funs/{funId}',
      method: 'PUT'
    },
    {
      path: '/ucenter/system/funs/{funId}',
      method: 'DELETE'
    },
    {
      path: '/ucenter/system/menus',
      method: 'GET'
    }]
  },
  api: {
    funKey: 'api',
    name: 'API',
    apis: [{
      path: '/ucenter/system/apis',
      method: 'GET'
    },
    {
      path: '/ucenter/system/funs/{funId}/apis',
      method: 'POST'
    }]
  }
}