import {
  funRequest
} from '@/api'

import {
  getOtherSysFunsConfig
} from '@/sys'

import ucenterFunConfig from '@/fun'

export const funConfig = ucenterFunConfig.concat(getOtherSysFunsConfig())

export const getRouterFunMap = () => {
  let routerFunMap = {}
  let useFunConfig = JSON.parse(JSON.stringify(funConfig))
  useFunConfig.forEach((v) => {
    let funs = Object.keys(v.funs).map(v3 => v.funs[v3])
    funs.forEach(fun => fun.apis = fun.apis ? fun.apis.map((api) => {
      let serviceName = api.path.split('/').filter(vs => vs)[0]
      return {
        serviceName,
        path: api.path.replace(serviceName, '').slice(1),
        method: api.method
      }
    }) : [])
    v.routerNames.forEach((v2) => {
      routerFunMap[v2] = funs.map(fun => Object.assign({}, fun, {
        funKey: v2 + '-' + fun.funKey
      }))
    })
  })
  return routerFunMap
}

export const dealMenuFuns = async (menu, funs) => {
  menu.funs = menu.funs || []
  let funsKeys = funs.map(v => v.funKey)
  let menuFunsKeys = menu.funs.map(v => v.funKey)
  let i = 0
  while (i < menu.funs.length) {
    let menuFun = menu.funs[i]
    if (!funsKeys.includes(menuFun.funKey)) {
      console.log('删除功能', menuFun)
      await funRequest.del(menuFun.id)
    }
    i += 1
  }
  i = 0
  while (i < funs.length) {
    let fun = funs[i]
    if (!menuFunsKeys.includes(fun.funKey)) {
      console.log('新增功能', fun)
      await funRequest.add(fun)
    } else {
      let menuFun = menu.funs.find(v => v.funKey === fun.funKey)
      console.log('更新功能', fun)
      await funRequest.update(menuFun.id, fun)
    }
    i += 1
  }
}

export const routerFunMap = getRouterFunMap()

export const updateFun = async (menus) => {
  // if (routerFunMap) {
  //   console.log(routerFunMap)
  //   return
  // }
  let i = 0
  while (i < menus.length) {
    let menu = menus[i]
    let funs = []
    if (menu.url && routerFunMap[menu.url]) {
      funs = routerFunMap[menu.url].map((f) => {
        return {
          funKey: f.funKey,
          name: f.name,
          menuIds: [menu.id],
          apis: f.apis
        }
      })
    }
    await dealMenuFuns(menu, funs)
    i += 1
  }
}