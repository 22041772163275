export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/ucenter/user',
      method: 'GET'
    }]
  },
  role: {
    funKey: 'role',
    name: '角色',
    apis: [{
      path: '/ucenter/user_org_role',
      method: 'GET'
    },
    {
      path: '/ucenter/org/all',
      method: 'GET'
    },
    {
      path: '/ucenter/system/roles/base',
      method: 'GET'
    },
    {
      path: '/ucenter/user_org_role',
      method: 'POST'
    },
    {
      path: '/ucenter/user_org_role/{id}',
      method: 'DELETE'
    }]
  },
  org_role: {
    funKey: 'org_role',
    name: '职务',
    apis: [{
      path: '/ucenter/user_org_role',
      method: 'GET'
    },
    {
      path: '/ucenter/org/all_tree',
      method: 'GET'
    },
    {
      path: '/ucenter/user_org_role',
      method: 'POST'
    },
    {
      path: '/ucenter/user_org_role/{id}',
      method: 'DELETE'
    }]
  },
  add: {
    funKey: 'add',
    name: '新增',
    apis: [{
      path: '/ucenter/user',
      method: 'POST'
    }]
  },
  update: {
    funKey: 'edit',
    name: '修改',
    apis: [{
      path: '/ucenter/user/{userId}',
      method: 'PUT'
    }]
  },
  sign_file: {
    funKey: 'sign_file',
    name: '签名',
    apis: [{
      path: '/ucenter/user/sign_file/{userId}',
      method: 'PUT'
    }]
  },
  // sort: {
  //   funKey: 'sort',
  //   name: '默认排序设置',
  //   apis: [{
  //     path: '/ucenter/user/sort/{userId}',
  //     method: 'PUT'
  //   }]
  // },
  del: {
    funKey: 'del',
    name: '删除',
    apis: [{
      path: '/ucenter/user/{userId}',
      method: 'DELETE'
    }]
  },
  reset_pwd: {
    funKey: 'reset_pwd',
    name: '重置密码',
    apis: [{
      path: '/ucenter/user/{userId}/password/default',
      method: 'PUT'
    }]
  }
}