export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/ucenter/org/tree',
      method: 'GET'
    },
    {
      path: '/ucenter/org_type',
      method: 'GET'
    }]
  },
  tree: {
    funKey: 'tree',
    name: '树形展示',
    apis: [{
      path: '/ucenter/org/tree',
      method: 'GET'
    },
    {
      path: '/ucenter/org/all',
      method: 'GET'
    }]
  },
  add: {
    funKey: 'add',
    name: '新增',
    apis: [{
      path: '/ucenter/org',
      method: 'POST'
    },
    {
      path: '/ucenter/org/role/{id}',
      method: 'PUT'
    },
    {
      path: '/ucenter/org_type',
      method: 'GET'
    },
    {
      path: '/ucenter/org/all',
      method: 'GET'
    },
    {
      path: '/ucenter/system/roles/base',
      method: 'GET'
    }]
  },
  update: {
    funKey: 'edit',
    name: '修改',
    apis: [{
      path: '/ucenter/org/{id}',
      method: 'PUT'
    },
    {
      path: '/ucenter/org_type',
      method: 'GET'
    },
    {
      path: '/ucenter/org/role/{id}',
      method: 'PUT'
    },
    {
      path: '/ucenter/org/all',
      method: 'GET'
    },
    {
      path: '/ucenter/system/roles/base',
      method: 'GET'
    }]
  },
  role: {
    funKey: 'role',
    name: '职务',
    apis: [{
      path: '/ucenter/user_org_role',
      method: 'GET'
    },
    {
      path: '/ucenter/system/roles/base',
      method: 'GET'
    }]
  },
  roleAuth: {
    funKey: 'roleAuth',
    name: '职务权限',
    apis: [{
      path: '/ucenter/user_org_role/{id}',
      method: 'PUT'
    },
    {
      path: '/ucenter/system/menus',
      method: 'GET'
    }]
  },
  delRole: {
    funKey: 'delRole',
    name: '删除职务',
    apis: [{
      path: '/ucenter/user_org_role/{id}',
      method: 'DELETE'
    }]
  },
  addRole: {
    funKey: 'addRole',
    name: '新增职务',
    apis: [{
      path: '/ucenter/user_org_role',
      method: 'POST'
    }]
  },
  del: {
    funKey: 'del',
    name: '删除',
    apis: [{
      path: '/ucenter/org/{id}',
      method: 'DELETE'
    }]
  },
  goWorker: {
    funKey: 'goWorker',
    name: '跳转人员管理'
  },
  goCount: {
    funKey: 'goCount',
    name: '跳转统计页面'
  }
}