export default {
  get: {
    funKey: 'get',
    name: '基础功能',
    apis: [{
      path: '/ucenter/sys_config',
      method: 'GET'
    },
    {
      path: '/ucenter/sys_config',
      method: 'POST'
    }]
  }
}