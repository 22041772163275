import org from './org'
import orgType from './orgType'
import placard from './placard'
import userGroup from './userGroup'
import fun from './fun'
import menu from './menu'
import role from './role'
import user from './user'
import orgInfo from './org_info'

export default [
  {
    routerNames: ['sys.user'],
    funs: user
  },
  {
    routerNames: ['config.user_group'],
    funs: userGroup
  },
  {
    routerNames: ['sys.role'],
    funs: role
  },
  {
    routerNames: ['sys.menu'],
    funs: menu
  },
  {
    routerNames: ['sys.fun'],
    funs: fun
  },
  {
    routerNames: ['sys.placard'],
    funs: placard
  },
  {
    routerNames: ['org.type'],
    funs: orgType
  },
  {
    routerNames: ['org.manage'],
    funs: org
  },
  {
    routerNames: ['org.home'],
    funs: {}
  },
  {
    routerNames: ['org.info'],
    funs: orgInfo
  },
  {
    routerNames: ['org.position'],
    funs: role
  }, {
    routerNames: ['todo'],
    funs: {}
  },
  {
    routerNames: ['config.sys'],
    funs: {}
  },
  {
    routerNames: ['config.select'],
    funs: {}
  },
  {
    routerNames: ['/pages/auth/signature'],
    funs: {
      get: {
        funKey: 'get',
        name: '基础功能',
        apis: [
          { path: '/ucenter/user/sign_file/my', method: 'PUT' }
        ]
      }
    }
  },
  {
    routerNames: ['/pages/worker/confirm'],
    funs: {
      get: {
        funKey: 'get',
        name: '基础功能',
        apis: [
          { path: '/basis/worker/confirm/{id}', method: 'PUT' },
          { path: '/basis/worker/my', method: 'GET' },
          { path: '/basis/worker/confirm/my', method: 'GET' },
          { path: '/basis/worker/confirm/info/my', method: 'GET' }
        ]
      }
    }
  }
]